




















































































































import { Component, Vue } from "vue-property-decorator";
import RegexUtils from "@/utils/RegexUtils";
import SublimeEmployeeFilterDTO from "@/dto/sublime/employee/SublimeEmployeeFilterDTO";
import RouteNames from "@/router/RouteNames";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { namespace } from "vuex-class";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import AdminService from "@/services/AdminService";
import SublimeEmployeeCreationModal from "@/components/admin/employee/SublimeEmployeeCreationModal.vue";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import CompanyFilterDTO from "@/dto/company/CompanyFilterDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: { PaginationComponent }
})
export default class SublimeEmployeesList extends Vue {
    private RouteNames = RouteNames;

    private successful = true;

    private message = '';

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private totalPages = 1;

    private filter = new SublimeEmployeeFilterDTO();

    private employees: Array<SublimeEmployeeDTO> = [];

    mounted() {
        this.loadEmployees();
    }

    private loadEmployees(): void {
        this.startLoading();
        this.message = "";
        console.log(this.filter)
        AdminService.getEmployees(this.filter).then(
            (response) => {
                this.employees = response.data.data;
                this.totalPages = response.data.countOfPages;
                this.successful = true;
                this.stopLoading();
            },
            (error) => {
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.stopLoading();
            }
        )
    }

    changePage(page: number) {
        this.filter.pageNumber = page;
    }

    get getPositionSortIcon(): string {
        return this.getSortIcon("POSITION");
    }

    get getFirstNameSortIcon(): string {
        return this.getSortIcon("FIRST_NAME");
    }

    get getLastNameSortIcon(): string {
        return this.getSortIcon("LAST_NAME");
    }

    getSortIcon(field: string) {
        if (this.filter.orderField !== field) {
            return "sort";
        } else if (this.filter.orderDirection === "ASC") {
            return "sort-up";
        } else {
            return "sort-down";
        }
    }

    handleSearch() {
        this.filter.pageNumber = 0;
        this.loadEmployees()
    }

    clearFilter() {
        this.filter = new SublimeEmployeeFilterDTO();
        this.loadEmployees();
    }

    switchOrder(field: string) {
        if (field === this.filter.orderField) {
            this.toggleDirection();
        } else {
            this.filter.orderField = field;
            this.filter.orderDirection = "ASC";
        }
        this.loadEmployees()
    }

    toggleDirection() {
        this.filter.orderDirection = this.filter.orderDirection === "ASC" ? "DESC" : "ASC";
    }

    handleName(event: KeyboardEvent) {
        if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key)) {
            event.preventDefault();
        }
    }

    private registerNewEmployee(): void {
        this.$modal.show(
            SublimeEmployeeCreationModal,
            {
                onSuccess: () => this.loadEmployees()
            },
            {
                width: "50%",
                height: "auto",
                scrollable: true,
            }
        );
    }
};
